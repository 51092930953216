import React from 'react';

import './IframeWrapper.css';

const IframeWrapper = ({ aspectRatio = 1, children }) => (
  <div className="IframeWrapper" style={{
    paddingTop: `${aspectRatio * 100}%`,
  }}>
    {children}
  </div>
);

export default IframeWrapper;
