import React from 'react'
import { graphql } from 'gatsby'
// import Image from 'gatsby-image'

import GlobalStyles from '../components/GlobalStyles/GlobalStyles'
import Grid from '../components/Grid/Grid'
import IframeWrapper from '../components/IframeWrapper/IframeWrapper'
import Section from '../components/Section/Section'
import Seo from '../components/Seo/Seo'

const EPKPage = ({
  data: {
    coverImage: {
      childImageSharp: {
        fluid: coverImageFluid
      },
    },
    markImage: {
      childImageSharp: {
        fluid: markImageFluid
      },
    },
  },
}) => (
  <GlobalStyles>
    <Seo title="Magical Trash - The Tower Album EPK" />
    <Section
      constrainWidth={false}
      style={{
        background: '#d71c4f',
        color: 'white',
      }}
    >
      <div style={{ maxWidth: '1600px', margin: '0 auto' }}>
        <h1 style={{
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
          fontSize: '1.8em',
          background: 'black',
          color: 'white',
          padding: '10px 25px',
          textAlign: 'left',
        }}>Magical Trash - "The Tower" Album</h1>
        <Grid>
          <div>
            <h3 style={{
              fontSize: '1.25em',
              borderBottom: '1px solid white',
              textAlign: 'left',
              lineHeight: '1.5',
            }}>
              Album Artwork
            </h3>
            {/* <Image fluid={coverImageFluid} style={{ border: '5px solid white' }} /> */}
          </div>

          <div>
            <h3 style={{
              fontSize: '1.25em',
              borderBottom: '1px solid white',
              textAlign: 'left',
              lineHeight: '1.5',
            }}>
              Artist Info
            </h3>
            <dl>
              <dt>Label:</dt><dd>Self-released</dd>
              <dt>Genre:</dt><dd>Post-punk / Experimental pop</dd>
              <dt>Located In:</dt><dd>Portland, OR</dd>
              <dt>Influences:</dt><dd>Broadcast, This Heat, Silver Apples, Brainiac, Severed Heads</dd>
              <dt>Sounds Like:</dt><dd>Liars, Suuns, Oneohtrix Point Never, EMA, Beak></dd>
            </dl>
          </div>

          <div>
            <h3 style={{
              fontSize: '1.25em',
              borderBottom: '1px solid white',
              textAlign: 'left',
              lineHeight: '1.5',
            }}>
              Biography
            </h3>
            <p style={{ color: 'black' }}>
              Magical Trash is the solo project of Mark E. Carter. He has been writing,
              recording, and playing music since his teenage years. Magical Trash initially
              started as a challenge to write and record an EP for every month of the year
              in 2016. After a period of relative quiet, the Magical Trash moniker returns
              with the release of "The Tower".
            </p>
            <p style={{ color: 'black' }}>
              Written and recorded over a period of two years, "The Tower" is an attempt to
              reconcile what it means to exist during a period of massive upheaval:
              to see the edges of a new world, yet feel powerless to transform the systems
              we are entangled within. Sounds on the album run the gamut from motorik beats to
              ambient interludes to snarling post-punk while the lyrical content focuses on
              loss: loss of biodiversity, loss of civility, loss of opportunity, and loss of
              loved ones. At its core it is a deeply personal experience that expands the
              personal world into the sociopolitical.
            </p>
            <p style={{ color: 'black' }}>
                Recommended for fans of Liars, Suuns, Oneohtrix Point Never, EMA, and Beak>.
            </p>
          </div>

          <div>
            <h3 style={{
              fontSize: '1.25em',
              borderBottom: '1px solid white',
              textAlign: 'left',
              lineHeight: '1.5',
            }}>
              Listen
            </h3>
            <IframeWrapper aspectRatio={1.925}>
              <iframe
                style={{ border: '0', maxWidth: '400px' }}
                src="https://bandcamp.com/EmbeddedPlayer/album=261795040/size=large/bgcol=ffffff/linkcol=0687f5/transparent=true/tracklist=true/tracks=1694583881,471967041,3958065188,4190322687,1642882071,2421027589,3266067996,3010860075/esig=f738c7203dbec4d9c724dff9bb893fc2/"
                seamless
                title="Bandcamp Player"
              >
                <a href="http://magicaltrash.bandcamp.com/album/the-tower">The Tower by Magical Trash</a>
              </iframe>
            </IframeWrapper>
          </div>

          <div>
            <h3 style={{
              fontSize: '1.25em',
              borderBottom: '1px solid white',
              textAlign: 'left',
              lineHeight: '1.5',
            }}>
              Video
            </h3>
            <IframeWrapper>
              <iframe
                width="800"
                height="800"
                src="https://www.youtube.com/embed/sqwhn9dFhc0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullscreen
                style={{
                  width: '100%',
                  maxWidth: '800px',
                }}
                title="Generational Debt Music Video"
              />
            </IframeWrapper>
          </div>

          <div>
            <h3 style={{
              fontSize: '1.25em',
              borderBottom: '1px solid white',
              textAlign: 'left',
              lineHeight: '1.5',
            }}>
              Photos
            </h3>
            {/* <Image fluid={markImageFluid} /> */}
          </div>

          <div>
            <h3 style={{
              fontSize: '1.25em',
              borderBottom: '1px solid white',
              textAlign: 'left',
              lineHeight: '1.5',
            }}>
              Online
            </h3>
            <ul>
              <li><a style={{ margin: '0 0 20px' }} className="button" href="//magicaltrash.bandcamp.com">Bandcamp</a></li>
              <li><a style={{ margin: '0 0 20px' }} className="button" href="//twitter.com/markecarter">Twitter</a></li>
            </ul>
          </div>
        </Grid>
      </div>
    </Section>
    <Section>
      <p style={{
        textAlign: 'center',
        color: '#d71c4f',
        fontSize: '0.75em',
        textTransform: 'uppercase',
      }}>©2020 Mark E. Carter / Magical Trash</p>
    </Section>
  </GlobalStyles>
)

export const query = graphql`
  query EPKQuery {
    coverImage: file(relativePath: { eq: "TheTower.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markImage: file(relativePath: { eq: "MarkPromo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default EPKPage
